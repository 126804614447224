import {
  create as createProject,
  getById as getProjectById,
  get as getProjects,
  getAndConcat as getProjectsAndConcat,
  update as updateProject
} from '../types/project.js'

const baseURL = process.env.GATSBY_API_URL;

const fetchInstance = async (url, options = {}) => {
  const response = await fetch(`${baseURL}${url}`, {
    credentials: "include",
    ...options,
  });
  if (!response.ok) {
    throw new Error("Network response was not ok");
  }
  return response.json();
};

export const create = dispatch => async (project) => {
  dispatch({ type: createProject.REQUEST });
  try {
    const data = await fetchInstance('/project', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
    });
    dispatch({ type: createProject.SUCCESS, data });
  } catch (error) {
    dispatch({ type: createProject.FAIL });
  }
};

export const getById = dispatch => async (projectId) => {
  dispatch({ type: getProjectById.REQUEST });
  try {
    const data = await fetchInstance(`/project/${projectId}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getProjectById.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getProjectById.FAIL });
  }
};

export const get = dispatch => async (queryParams) => {
  const queryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
  
  dispatch({ type: getProjects.REQUEST });
  try {
    const data = await fetchInstance(`/project?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getProjects.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getProjects.FAIL });
  }
};

export const getAndConcat = dispatch => async (queryParams) => {
  const queryString = Object.keys(queryParams)
    .map(key => `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`)
    .join('&');
  
  dispatch({ type: getProjectsAndConcat.REQUEST });
  try {
    const data = await fetchInstance(`/project?${queryString}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
      },
    });
    dispatch({ type: getProjectsAndConcat.SUCCESS, data });
  } catch (error) {
    dispatch({ type: getProjectsAndConcat.FAIL });
  }
};

export const update = dispatch => async (project) => {
  dispatch({ type: updateProject.REQUEST });
  try {
    const data = await fetchInstance('/project', {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(project),
    });
    dispatch({ type: updateProject.SUCCESS, data });
  } catch (error) {
    dispatch({ type: updateProject.FAIL });
  }
};
