import React, {useState} from "react"
import Category from '../category/index'
import * as styles from './styles.module.css'
const SearchBanner = ({onlyCategorySearch}) => {
  const [moreOptions, setMoreOptions] = useState(false)
  return (
    <div className={styles.container}>
      {!onlyCategorySearch &&
      <div className={styles.basicSearch}>
        <input placeholder={'¿Qué servicio buscas?'}/>
        <div>
          <button className={'primary'}>Buscar</button>
          {moreOptions && <button className={'primary'} onClick={() => setMoreOptions(false)}>Menos opciones</button>}
          {!moreOptions && <button className={'primary'} onClick={() => setMoreOptions(true)}>Más opciones</button>}
        </div>
      </div>
      }
      {(moreOptions || onlyCategorySearch) &&
      <div className={styles.categories}>
        <div>
          <input placeholder={'Busca una categoría'} list="categories"/>
          <datalist id="categories">
            <option value="Edge"/>
            <option value="Firefox"/>
            <option value="Chrome"/>
            <option value="Opera"/>
            <option value="Safari"/>
          </datalist>
        </div>
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map((i) =>
          <div key={i} className={styles.selectedCategory}>
            <Category label={'prueba ' + i}/>
          </div>
        )}
      </div>
      }
    </div>
  )
}
export default SearchBanner
